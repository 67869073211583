<template>
<div class="contact-view white-bg  owf-h">
    <div class="flex justify-center">
        <div class="contact-top-view mw-1920 pt-200">
            <h1 class="top-h1 tal" data-aos="fade-down">{{contact.data[`title_${$i18n.locale}`]}}</h1>
            <p class="top-p mt-20px tal" data-aos="fade-down"> {{contact.data[`description_${$i18n.locale}`]}} </p>
            <div class="contact-top-bg">
                <img :src="contact.files.contactImage? `https://avior.prestigeautofl.com${contact.files.contactImage}`:require(`@/assets/img/stockbg.svg`)" alt="">
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="contact mw-1920  w-full">
            <div class="contact-items">
                <div class="contact-item-left contact-item">
                    <div class="contact-phone">
                        <h1 data-aos="fade-up">{{contact.data.phone}}</h1>
                        <p data-aos="fade-up"> {{contact.data[`phone_title_${$i18n.locale}`]}} </p>
                    </div>
                    <div class="contact-mail">
                        <p data-aos="fade-up">{{$t('help_mail')}} <span> {{contact.data.mail1}} </span> </p>
                        <p data-aos="fade-up">{{$t('help_mail')}} <span>{{contact.data.mail2}}</span> </p>
                        <p data-aos="fade-up">{{$t('help_mail')}} <span> {{contact.data.mail3}} </span> </p>
                    </div>
                    <div class="contact-adress">
                        <p data-aos="fade-up"> {{contact.data[`adress_${$i18n.locale}`]}} </p>
                    </div>
                </div>
                <div class="contact-item">
                    <el-form ref="smsForm" :model="sms" :rules="smsRules">
                        <div class="contact-card" v-loading="smsLoading">
                            <p>{{$t('coming_soon')}} </p>
                            <el-form-item prop="name">
                                <el-input :placeholder="$t('enter_mail')" class="contact-card-input" v-model="sms.name"></el-input>
                            </el-form-item>
                            <el-form-item prop="text">
                                <el-input type="textarea" :rows="5" class="contact-card-text-area" :placeholder="$t('enter_text')" v-model="sms.text"> </el-input>
                            </el-form-item>
                            <div class="flex justify-center">
                                <button class="button" type="button" @click="SendSms()"> {{$t('contact_send')}}</button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'contact',
    data() {
        return {
            sms: {
                name: '',
                text: ''
            },
            smsLoading: false,
            smsRules: {
                name: [{
                    required: true,
                    type: 'email',
                    message: this.$t('input_mail'),
                    trigger: 'change'
                }],
                text: [{
                    required: true,
                    message: this.$t('input_text_please'),
                    trigger: 'change'
                }],
            }

        }
    },
    computed: {
        contact() {
            return this.$store.state.Contact
        },
    },
    methods: {
        SendSms() {
            this.$refs.smsForm.validate((valid) => {
                if (valid) {
                    this.$notify({
                        title: 'Успех',
                        message: 'Ваш запрос был успешно отправлен!',
                        type: 'success'
                    });
                    this.smsLoading = false
                    this.$refs.smsForm.resetFields();
                } else {
                    return false;
                }
            });
        }
    }

}
</script>

<style lang="scss">
.contact {
    width: 100%;
    padding: 0px 120px;

    .contact-items {
        display: flex;
        flex-wrap: wrap;

    }

    .contact-item {
        width: 50%;
    }

    .contact-item-left {
        padding: 60px 0px;
    }

    .contact-phone {
        h1 {
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem;
            color: var(--other-black);
        }

        p {
            font-family: Stem-Medium;
            font-size: 0.875rem;
            line-height: 1rem;
            color: var(--white-black);
            margin-top: 10px;
        }
    }

    .contact-mail {
        padding: 50px 0px 60px 0px;

        p {
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--white-black);
            margin-top: 10px;

            span {
                color: var(--other-black);
                margin-left: 10px;
            }
        }
    }

    .contact-adress {
        p {
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem;
            color: var(--other-black);
        }
    }

    .contact-card {
        width: 100%;
        overflow: hidden;
        max-width: 600px;
        padding: 40px 40px;
        box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
        border-radius: 10px;
        background: var(--white-bg);
        transform: translateY(-60px);

        p {
            font-family: Stem-Regular;
            font-size: 20px;
            line-height: 1.5rem;
            color: var(--other-black);
        }

        .contact-card-input {

            width: 100%;

            .el-input__inner {
                border: 1.5px solid #DDDFE2;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 18px 30px;
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 1.25rem;
                height: auto;
                color: var(--white-black);
                margin-top: 25px;
            }
        }

        .contact-card-text-area {
            margin-top: 20px;
            width: 100%;

            .contact-card-input {

                width: 100%;

                .el-input__inner {
                    border: 1.5px solid #DDDFE2;
                    box-sizing: border-box;
                    border-radius: 4px;
                    padding: 18px 30px;
                    font-family: Stem-Regular;
                    font-size: 1.125rem;
                    line-height: 1.25rem;
                    height: auto;
                    color: var(--white-black);
                    margin-top: 25px;
                }
            }

            .contact-card-text-area {
                margin-top: 20px;
                width: 100%;

                .el-textarea__inner {
                    padding: 18px 30px;
                    font-family: Stem-Regular;
                    font-size: 1.125rem;
                    line-height: 1.25rem;
                    height: auto;
                    color: var(--white-black);
                    border: 1.5px solid #DDDFE2;
                    box-sizing: border-box;
                    border-radius: 4px;
                }
            }
        }

        .el-textarea__inner {
            width: 100%;
            height: 120px;
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: var(--white-black);
            outline: none;
            padding: 20px 30px;
        }

        .is-error {
            input {
                color: #F56C6C;
                border: 1.5px solid #F56C6C;
            }

            textarea {
                color: #F56C6C;
                border: 1.5px solid #F56C6C;
            }
        }

        button {
            margin-top: 40px;
        }
    }
}

.contact-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px 120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.contact-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}

@media screen and(max-width:1100px) {
    .contact-top-view {
        padding: 100px 20px;
    }

    .contact {
        padding: 0px 20px;
    }
}

@media screen and(max-width:950px) {
    .contact-top-view {
        padding: 100px 20px;
    }

    .contact {
        .contact-item {
            width: 100%;
            padding: 20px 0px;
        }

        .contact-card {
            transform: translateY(0px);
        }
    }
}

@media screen and(max-width:768px) {
    .contact-top-view {
        padding: 60px 20px 100px 20px;

        .tal {
            text-align: center !important;
        }
    }

    .contact-top-bg {
        display: none;
    }

    .contact {
        .contact-items {
            flex-direction: column-reverse;
        }

        .contact-item {
            padding: 0;
        }

        .contact-card {
            transform: translateY(-50px);
        }

        .contact-item-left {
            padding: 40px 0px;
        }

        .contact-phone {
            h1 {
                font-size: 1rem;
                line-height: 1.125rem;
            }

            p {
                font-size: 0.75rem;
                line-height: 1.125rem;
            }
        }

        .contact-mail {
            padding: 30px 0px 40px 0px;

            p {
                font-size: 1rem;
                line-height: 1.125rem;
                text-align: left;

            }
        }

        .contact-adress {
            p {
                font-size: 1rem;
                line-height: 1.125rem;
            }
        }

        .contact-card {
            padding: 25px;

            p {
                font-size: 1rem;
                line-height: 1.125rem;
            }

            .contact-card-input {
                .el-input__inner {
                    padding: 15px 20px;
                    margin-top: 25px;
                    font-size: 1rem;
                    line-height: 1.125rem;
                }
            }

            .contact-card-text-area {
                margin-top: 20px;
                width: 100%;

                .el-textarea__inner {
                    font-size: 1rem;
                    line-height: 1.125rem;
                    padding: 15px 20px;
                }
            }

            button {
                margin-top: 40px;
            }
        }
    }
}
</style>
